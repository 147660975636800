import { Controller } from "stimulus";
import { EventGeckoChartRendered, EventGeckoPieChartExpanded, EventPriceLoaded, EventFocusPortfolioCoinSearch } from "../../events";
import { trackEvent } from "../../analytics";
import { SELECT_PORTFOLIO_VISUALIZATION_TOGGLE } from "../../mixpanel_config";

const SETTINGS_KEY = (portfolioId) => `portfolio:${portfolioId}`;
const HOLDINGS_KEY = "holdings";
const CHARTS_KEY = "charts";
const DEFAULT_SETTINGS = {
  [HOLDINGS_KEY]: null,
  [CHARTS_KEY]: null,
  h1: true,
  h24: true,
  d7: true,
  d30: false,
  d60: false,
  mcap: true,
  fdv: false,
  ratio: false,
};

export default class extends Controller {
  static targets = ["holdings", "charts", "table", "showHoldingsButton", "hideHoldingsButton", "toggleChartsButton", "holdingsTable", "holdingsTableLoading"];

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;

    const settingsJson = localStorage.getItem(SETTINGS_KEY(this.portfolioId));
    this.settings = settingsJson ? JSON.parse(settingsJson) : DEFAULT_SETTINGS;

    // Has holdings now; show holdings by default.
    if (this.hasHoldingsTarget && this.settings[HOLDINGS_KEY] === null) {
      this.settings[HOLDINGS_KEY] = true;
    }

    if (this.hasChartsTarget && this.settings[CHARTS_KEY] === null) {
      this.settings[CHARTS_KEY] = true;
    }


    // Previously had holdings, but not anymore. Reset to "null" value.
    // Ensures that if a user clears and re-adds txns, the holdings view will show again regardless.
    if (!this.hasHoldingsTarget && this.settings[HOLDINGS_KEY] !== null) {
      this.settings[HOLDINGS_KEY] = null;
      localStorage.setItem(SETTINGS_KEY(this.portfolioId), JSON.stringify(this.settings));
    }

    if (!this.hasChartsTarget && this.settings[CHARTS_KEY] !== null) {
      this.settings[CHARTS_KEY] = null;
      localStorage.setItem(SETTINGS_KEY(this.portfolioId), JSON.stringify(this.settings));
    }


    const hasHoldings = this.hasHoldingsTarget && this.holdingsTarget.dataset.overview !== "true";
    if (this.hasChartTarget || this.hasTableTarget || hasHoldings) {
      this.applySettings();
      this.applyHoldings();
    }

    // We need to know if the chart has rendered to decide whether to trigger a rerender.
    if (this.hasChartsTarget) {
      this.applyCharts();

      this.chartRenderCount = 0;
      this.element.addEventListener(EventGeckoChartRendered, () => this.chartRenderCount++);
      this.element.addEventListener(EventGeckoPieChartExpanded, () => this.handleHoldingsModal());
    }
  }


  showChartModal() {
    Modal.show("portfolio_charts");

    const modalChartGroupElement = this.element.querySelector("[data-chart-group='modal']");
    const holdingsChartElement = modalChartGroupElement.querySelector("[data-portfolio-target='holdingsChart']");
    const performanceChartElement = modalChartGroupElement.querySelector("[data-portfolio-target='performanceChart']");

    holdingsChartElement.dataset.pieChartRerenderValue = true;
    performanceChartElement.dataset.genericChartRerenderValue = true;
  }

  handleChartChange(e) {
    const chartGroupElement = e.currentTarget.closest("[data-chart-group]");
    const targetElement = chartGroupElement.querySelector("[data-portfolio-target='performanceChart']");

    targetElement.dataset.dataUrl = e.currentTarget.dataset.url;
    targetElement.dataset.genericChartRerenderValue = true;
  }


  handleCharts() {
    this.settings[CHARTS_KEY] = !this.settings[CHARTS_KEY];
    localStorage.setItem(SETTINGS_KEY(this.portfolioId), JSON.stringify(this.settings));

    this.applyCharts();
    this._toggleSetting(CHARTS_KEY, this.settings[CHARTS_KEY]);

    const defaultChartGroupElement = this.element.querySelector("[data-chart-group='default']");
    const holdingsChartElement = defaultChartGroupElement.querySelector("[data-portfolio-target='holdingsChart']");
    const performanceChartElement = defaultChartGroupElement.querySelector("[data-portfolio-target='performanceChart']");

    holdingsChartElement.dataset.pieChartRerenderValue = true;
    performanceChartElement.dataset.genericChartRerenderValue = true;
    trackEvent(SELECT_PORTFOLIO_VISUALIZATION_TOGGLE, { visible: this.settings[CHARTS_KEY] })
  }

  applyCharts() {
    if (!this.hasToggleChartsButtonTarget) {
      return;
    }

    const enabled = this.settings[CHARTS_KEY];
    this.toggleChartsButtonTarget.querySelector(".far").classList.toggle("fa", enabled);
  }


  handleHoldings() {
    this.settings[HOLDINGS_KEY] = !this.settings[HOLDINGS_KEY];
    localStorage.setItem(SETTINGS_KEY(this.portfolioId), JSON.stringify(this.settings));

    this.applyHoldings();
    this._toggleSetting(HOLDINGS_KEY, this.settings[HOLDINGS_KEY]);
  }

  applyHoldings() {
    if (!this.hasShowHoldingsButtonTarget || !this.hasHideHoldingsButtonTarget) {
      return;
    }

    const enabled = this.settings[HOLDINGS_KEY];

    this.showHoldingsButtonTarget.classList.toggle("2lg:!tw-hidden", enabled);
    this.hideHoldingsButtonTarget.classList.toggle("2lg:!tw-hidden", !enabled);
  }

  handleHoldingsModal() {
    const modalSuffix = this.portfolioId || "overview";
    Modal.show(`portfolio_holdings_table_${modalSuffix}`);

    if (!this.holdingsModalLoaded) {
      this.reloadHoldingsTable();
    }
  }

  reloadHoldingsTable(e) {
    this.holdingsTableLoadingTarget.classList.remove("!tw-hidden");

    let url = e?.currentTarget?.dataset?.url;
    if (this.portfolioId) {
      url ||= `/${I18n.locale}/portfolios/${this.portfolioId}/holdings`;
    } else {
      url ||= `/${I18n.locale}/portfolios/all_holdings`
    }

    fetch(url, { credentials: "same-origin" })
      .then(async (response) => {
        if (response.ok) {
          const html = await response.text();
          this.holdingsTableTarget.innerHTML = html;
          this.holdingsTableLoadingTarget.classList.add("!tw-hidden");

          this.holdingsModalLoaded = true;
          window.dispatchEvent(new CustomEvent(EventPriceLoaded));
        }
      })
      .catch(() => Toaster.toast("An unknown error occurred", { icon: { classes: "fas fa-times tw-text-danger-500" } }));
  }


  handleSettings(e) {
    const columnKey = e.currentTarget.dataset.columnKey;
    const toggleTarget = e.currentTarget.querySelector("input");

    toggleTarget.checked = !toggleTarget.checked;

    this.settings[columnKey] = toggleTarget.checked;
    localStorage.setItem(SETTINGS_KEY(this.portfolioId), JSON.stringify(this.settings));

    this._toggleSetting(columnKey, this.settings[columnKey]);
  }

  applySettings() {
    // Highlight all toggle buttons based on settings.
    this.element.querySelectorAll("[data-column-key]").forEach((element) => {
      let columnKey = element.dataset.columnKey;
      let toggleTarget = element.querySelector("input");

      toggleTarget.checked = this.settings[columnKey];
    });

    // Hide/unhide columns based on settings.
    for (const [key, value] of Object.entries(this.settings)) {
      this._toggleSetting(key, value);
    }
  }

  focusSearchInput() {
    window.dispatchEvent(
      new CustomEvent(EventFocusPortfolioCoinSearch, {})
    );
  }

  _toggleSetting(key, show) {
    this.element
      .querySelectorAll(`td.${key}, th.${key}, div.gecko-section.${key}`)
      .forEach(element => {
        element.classList.toggle("tw-hidden", !show);
        element.classList.toggle("2lg:!tw-hidden", !show);
      });

    // Force a chart rerender if it hasn't loaded yet.
    if (key === "holdings" && parseInt(this.chartRenderCount) <= 0) {
      const defaultChartGroupElement = this.element.querySelector("[data-chart-group='default']");
      const holdingsChartElement = defaultChartGroupElement.querySelector("[data-portfolio-target='holdingsChart']");
      const performanceChartElement = defaultChartGroupElement.querySelector("[data-portfolio-target='performanceChart']");

      holdingsChartElement.dataset.pieChartRerenderValue = true;
      performanceChartElement.dataset.genericChartRerenderValue = true;
    }
  }
}
