import { Controller } from "stimulus";
import { copyToClipboardVirtual } from "../../../util/copy_to_clipboard";
import { getCSRFToken } from "../../../util";
import { GeckoPrimerInputError } from "../../../events";

export default class extends Controller {
  static targets = ["icon", "slugDisplay", "slugInput"];

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
    this.isPrivate = this.element.dataset.private === "true";

    this.publicReference = this.slugInputTarget.value;
    this.publicUrl = this.element.dataset.publicUrl;
  }


  handleShare() {
    if (!this.isPrivate) {
      return Modal.show("portfolio_share_status");
    }

    Modal.confirm(
      I18n.t("portfolio.private.set_public"),
      I18n.t("portfolio.private.set_public_explanation"),
      I18n.t("portfolio.private.set_as_public"),
    ).then((result) => {
      if (!result.confirm) {
        return;
      }

      const url = `/en/portfolios/${this.portfolioId}/share`;
      fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": getCSRFToken(),
        }
      }).then((response) => response.json())
        .then((json) => this._handleShareResponse(json))
        .catch((error) => {
          throw error;
        });
    });
  }

  _handleShareResponse(data) {
    if (data.private !== false) {
      return Toaster.toast(I18n.t("An unknown error occurred: " + data));
    }

    this.iconTarget.classList.remove("far");
    this.iconTarget.classList.add("fas");
    this.isPrivate = data.private;

    Toaster.toast(I18n.t("portfolio.private.now_public"));
    Modal.show("portfolio_share_status");
  }


  handleCopyLink() {
    copyToClipboardVirtual(this.publicUrl);
    Toaster.toast(I18n.t("portfolio.private.link_copied"));
  }

  handleSetAsPrivate() {
    const url = `/en/portfolios/${this.portfolioId}/unshare`;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => response.json())
      .then((json) => this._handleSetAsPrivateResponse(json))
      .catch((error) => {
        throw error;
      });
  }

  _handleSetAsPrivateResponse(data) {
    if (data.private !== true) {
      return Toaster.toast(I18n.t("An unknown error occurred: " + data));
    }

    this.iconTarget.classList.add("far");
    this.iconTarget.classList.remove("fas");
    this.isPrivate = data.private;

    Toaster.toast(I18n.t("portfolio.private.now_private"));
    Modal.hide("portfolio_share_status");
  }


  validateSlug(e) {
    const target = e.currentTarget || e;

    if (target.value === this.publicReference || target.value.length === 0) {
      this.slugInputTarget.dispatchEvent(GeckoPrimerInputError(false));
      return true;
    }

    if (target.value.length > 30) {
      const error = GeckoPrimerInputError(true, I18n.t("portfolio.slug.maximum_30_characters"));
      this.slugInputTarget.dispatchEvent(error);

      return false;
    }

    if (!target.value.match(/^[a-z0-9-]+$/)) {
      const error = GeckoPrimerInputError(true, I18n.t("portfolio.slug.limited_to"));
      this.slugInputTarget.dispatchEvent(error);

      return false;
    }

    this.slugInputTarget.dispatchEvent(GeckoPrimerInputError(false));
    return true;
  }

  handleLinkCancel() {
    this.slugInputTarget.value = this.publicReference;
    Modal.hide("portfolio_share_link");
  }

  handleLinkSave() {
    const slug = this.slugInputTarget.value;
    if (slug === this.publicReference) {
      Modal.hide("portfolio_share_link");
      Toaster.toast(I18n.t("portfolio.private.link_updated"));

      return;
    }

    if (!this.validateSlug(this.slugInputTarget)) {
      return;
    }

    const params = { slug };
    const url = `/en/portfolios/${this.portfolioId}/update_share_link`;

    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => this._handleLinkSaveResponse(response))
      .catch((error) => {
        throw error;
      });
  }

  async _handleLinkSaveResponse(response) {
    const data = await response.json();

    if (response.status === 422) {
      return this.slugInputTarget.dispatchEvent(GeckoPrimerInputError(true, data.message));
    }

    this.publicReference = data.slug;
    this.publicUrl = data.path;

    this.slugInputTarget.dispatchEvent(GeckoPrimerInputError(false));
    this.slugInputTarget.value = this.publicReference;
    this.slugDisplayTarget.value = this.publicReference;

    Modal.hide("portfolio_share_link");
    Toaster.toast(I18n.t("portfolio.private.link_updated"));
  }
}
