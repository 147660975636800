import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static values = { key: String }

  setCookie() {
    Cookies.set(this.keyValue, "true");
  }
}
