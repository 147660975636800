import { Controller } from "stimulus";
import { EventSelectStaticDropdownAction } from "../../events";
import { addPortfolioCoinRequest, removePortfolioCoinRequest } from "../../routes/portfolio";

export default class extends Controller {
  portfolioCoinsIds = null;

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;

    fetch(`/${I18n.locale}/portfolios/all_coins`)
      .then(async response => {
          if (!response.ok) {
            throw new Error(`Could not retrieve portfolio data. Error ${response.status}.`);
          }

          const data = await response.json();
          if (!data) {
            throw new Error(`Could not retrieve portfolio data. Invalid portfolio data returned.`);
          }

          this.portfolios = data;
          this.portfolio = this.portfolios.find(x => x.id === this.portfolioId);
          this.portfolioCoinsIds = this.portfolio.coin_ids;

          this._updateAllStars();
        }
      );
  }

  handle(e) {
    this._handlePreventDefault(e);

    let coinId = e.currentTarget.getAttribute("data-coin-id");
    let portfolioId = this.portfolioId;
    if (!coinId || !portfolioId) return;

    const hasCoin = this.portfolioCoinsIds.includes(+coinId);
    if (hasCoin) {
      this._removeCoin(+coinId, +portfolioId);
    } else {
      this._addCoin(+coinId, +portfolioId);
    }
  }

  _removeCoin(coinId, portfolioId) {
    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then((result) => {
      if (!result.confirm) return;

      removePortfolioCoinRequest(
        { coinId, portfolioId },
        (res) => {
          // Update local data to remove coin ID.
          let coinIdIndex = this.portfolioCoinsIds.indexOf(coinId);
          if (coinIdIndex !== -1) {
            this.portfolioCoinsIds.splice(coinIdIndex, 1);
          }

          Toaster.toast(I18n.t("portfolio.remove_coin.success", { portfolioTitle: this.portfolio.title }));
          this._updateAllStars();
        })
    });
  }

  _addCoin(coinId, portfolioId) {
    addPortfolioCoinRequest(
      { coinId, portfolioId },
      "POST",
      (res) => {
        this.portfolioCoinsIds.push(res.coin_id);

        Toaster.toast(
          I18n.t("portfolio.add_coin.success", { portfolioTitle: this.portfolio.title }),
          {
            action: {
              message: I18n.t("portfolio.refresh_portfolio"),
              options: {
                href: `/${I18n.locale}/portfolios/${portfolioId}`,
              }
            }
          }
        );

        this._updateAllStars();
      });
  }

  _updateAllStars() {
    const coinIdStar = {}
    this.portfolioCoinsIds.forEach(id => coinIdStar[id] = true);

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const enabled = !!coinIdStar[coinId];

      star.classList.toggle("far", !enabled);
      star.classList.toggle("fas", enabled);
      star.classList.toggle("tw-text-yellow-500", enabled);
    });
  }

  _handlePreventDefault(e) {
    if(e.currentTarget.dataset.preventDefault) {
      e.preventDefault();
    }
  }
}
