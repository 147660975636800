import { Controller } from "stimulus";
import { getCSRFToken, setUpdatedCsrfMetaContent } from "../../../util";

export default class extends Controller {
  static targets = ["defaultBadge", "setAsDefaultButton", "deleteButton"];

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
  }


  async handleSetAsDefault() {
    const url = `/en/portfolios/${this.portfolioId}`;
    const params = { portfolioId: this.portfolioId, default: true };

    await setUpdatedCsrfMetaContent();
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => response.json())
      .then((json) => this._handleSetAsDefaultResponse(json))
      .catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, { icon: { classes: "fas fa-times tw-text-danger-500" } }));
  }

  _handleSetAsDefaultResponse(data) {
    if (!data) {
      Toaster.toast("An unexpected error occurred: " + data);
      return;
    }

    this.defaultBadgeTarget.classList.remove("tw-hidden");

    this.setAsDefaultButtonTarget.remove();
    this.deleteButtonTarget.remove();

    Toaster.toast(I18n.t("portfolio.default.successful"));
  }


  async handleClearTransactions() {
    const result = await Modal.danger(
      I18n.t("portfolio.clear.title"),
      I18n.t("portfolio.clear.confirmation"),
      I18n.t("labels.clear"),
      I18n.t("labels.cancel")
    );

    if (!result.confirm) {
      return;
    }

    await setUpdatedCsrfMetaContent();
    const url = `/en/portfolios/${this.portfolioId}/reset`;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => response.json())
      .then((json) => this._handleClearTransactionsResponse(json))
      .catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, { icon: { classes: "fas fa-times tw-text-danger-500" } }));
  }

  _handleClearTransactionsResponse(data) {
    if (!data || data.message !== "ok") {
      Toaster.toast(I18n.t("metamask.unknown_error"));
      return;
    }

    Toaster.toast(I18n.t("portfolio.clear.successful"));
    window.location.reload();
  }


  handleDelete() {
    Modal.danger(
      I18n.t("portfolio.delete.title"),
      I18n.t("portfolio.delete.confirmation"),
      I18n.t("labels.delete"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this.portfolioId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => response.json())
        .then((json) => this._handleDeleteResponse(json))
        .catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, { icon: { classes: "fas fa-times tw-text-danger-500" } }));
    });
  }

  _handleDeleteResponse(data) {
    if (!data.message || data.message !== "success") {
      Toaster.toast("An unexpected error occurred: " + data);
      return;
    }

    Toaster.toast(I18n.t("portfolio.delete.successful"));
    window.location.href = `/${I18n.locale}/portfolio`;
  }


  handleRemoveCoin(e) {
    const coinId = e.currentTarget.dataset.coinId;

    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this.portfolioId}/coins/${coinId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          window.location.reload();
        }
      }).catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, { icon: { classes: "fas fa-times tw-text-danger-500" } }));
    });
  }


  handleRemoveTransaction(e) {
    const coinSlug = e.currentTarget.dataset.coinSlug;
    const transactionId = e.currentTarget.dataset.transactionId;

    Modal.danger(
      I18n.t("portfolio.remove_transaction.title"),
      I18n.t("portfolio.remove_transaction.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/${I18n.locale}/portfolios/${this.portfolioId}/${coinSlug}/transactions/${transactionId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          Toaster.toast(I18n.t("portfolio.remove_transaction.success"))
          window.location.reload();
        }
      }).catch((error) => console.error(error));
    });
  }
}
