import { Controller } from "stimulus";
import {
  debounce,
  getCSRFToken, getRecentSearchIds,
  isMobileDevice,
  sanitizeRecentSearchesLocalStorage,
  setUpdatedCsrfMetaContent
} from "../../../util";
import { getSmartResultHtmlByType } from "../../../template/smart_results_templates";
import { getActiveCurrency } from "../../../util/currency";
import { EventFocusPortfolioCoinSearch, EventPriceLoaded, EventTabItemPointerentered } from "../../../events";

const MAX_SEARCHED_COINS_COUNT = 3;

export default class extends Controller {
  static targets = [
    // Search bar
    "searchInput",

    // Coins
    "defaultCoinsContainer", "searchResultsContainer", "loading",

    // Highlight pills
    "defaultHighlightPills", "searchHighlightPills",

    // Recently searched
    "shortResultsContainer", "shortResults",

    // Smart results
    "smartResultContainer"
  ];

  // We use this variable to prevent race conditions from AJAX calls
  // completing at different times.
  _ajaxCount = 0;
  _reload = false;
  _selectedPill = null;

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
    this.portfolioCoinIds = JSON.parse(this.element.dataset.portfolioCoinIds);

    this.handleSearchInput = debounce(this._handleSearchInput.bind(this), 300);
    this.renderSmartResult = debounce(this.renderSmartResult, 100);

    this._focusSearchInput();
    this._loadRecentlySearched();
    this._renderFirstCoinSmartResult();
    this._handlePortfolioRefresh();
    this._updateAllStars();

    this._selectedPill = this.defaultHighlightPillsTarget.querySelector("nav a.selected");
  }

  handleCoin(e) {
    const coinId = +e.currentTarget.dataset.coinId;
    this._reload = true;

    if (this.portfolioCoinIds.includes(coinId)) {
      this._removeCoinFromPortfolio(coinId);
      return;
    }

    this._addCoinToPortfolio(coinId);
  }

  updateActivePill(e) {
    const pillIdOfMouseoveredElement = e.currentTarget.dataset.tabId;

    if (pillIdOfMouseoveredElement === this._selectedPill.id) {
      return;
    }

    this._selectedPill = this.defaultHighlightPillsTarget.querySelector(`#${pillIdOfMouseoveredElement}`);
    this._selectedPill.dispatchEvent(new CustomEvent(EventTabItemPointerentered));
    this._selectedPill.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
  }

   _removeCoinFromPortfolio(coinId) {
    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this.portfolioId}/coins/${coinId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          this._removeFromPortfolioCoins(coinId);
          this._updateAllStars();
        }
      }).catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
    });
  }

   async _addCoinToPortfolio(coinId) {
     this.loadingTarget.classList.remove("!tw-hidden");

     await setUpdatedCsrfMetaContent();
     const url = `/en/portfolios/${this.portfolioId}/coins/${coinId}`;
     fetch(url, {
       credentials: "same-origin",
       method: "POST",
       headers: {
         "Content-Type": "application/json; charset=utf-8",
         "X-CSRF-Token": getCSRFToken(),
       }
     }).then((response) => {
       if (!response.ok) {
         Toaster.toast("An unknown error occurred", {
           icon: {
             classes: "fas fa-times tw-text-danger-500"
           }
         });
       } else {
         this.portfolioCoinIds.push(coinId);
         this._updateAllStars();
         this.loadingTarget.classList.add("!tw-hidden");
       }
     })
   }

  renderSmartResult(e) {
    if (isMobileDevice(navigator.userAgent)) {
      return;
    }

    this.smartResultContainerTarget.innerHTML = getSmartResultHtmlByType(e);

    this.element.dispatchEvent(
      new CustomEvent(EventPriceLoaded, {
        bubbles: true
      })
    );
  }

  scrollToDivider(e) {
    let dividerId = e.currentTarget.dataset.divider;
    let scrollToElement = document.getElementById(dividerId);

    scrollToElement?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

    if (this._selectedPill === e.currentTarget) {
      return;
    }

    this._selectedPill = e.currentTarget;
  }

  _handleSearchInput() {
    const query = this.searchInputTarget.value;

    this._ajaxCount += 1;
    const ajaxCount = this._ajaxCount;

    if (!query) {
      this.defaultCoinsContainerTarget.classList.remove("tw-hidden");
      this.searchResultsContainerTarget.classList.add("tw-hidden");
      this.defaultHighlightPillsTarget.classList.remove("tw-hidden");

      this._updateAllStars();
      this.loadingTarget.classList.add("!tw-hidden");

      return;
    }

    this.loadingTarget.classList.remove("!tw-hidden");

    const params = new URLSearchParams();
    params.set("query", query);
    params.set("vs_currency", getActiveCurrency());

    const url = `/${I18n.locale}/portfolios/${this.portfolioId}/search?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.text())
      .then(result => {
        if (!result || this._ajaxCount !== ajaxCount) {
          return;
        }

        this.defaultHighlightPillsTarget.classList.add("tw-hidden");
        this.defaultCoinsContainerTarget.classList.add("tw-hidden");
        this.searchResultsContainerTarget.innerHTML = result;
        this.searchResultsContainerTarget.classList.remove("tw-hidden");
        this._updateAllStars();
        this.loadingTarget.classList.add("!tw-hidden");
      });
  }

  _renderFirstCoinSmartResult(){
    let element = document.querySelector("#portfolio-trending-divider");
    this.renderSmartResult(element.nextElementSibling);
  }

  _loadRecentlySearched() {
    const recentlySearchedChip = document.getElementById("tab-recently-searched")
    const recentSearchIds = getRecentSearchIds(MAX_SEARCHED_COINS_COUNT);

    if (recentSearchIds.length === 0) {
      let trendingDivider = document.getElementById("portfolio-trending-divider");
      trendingDivider.classList.remove("tw-my-2");
      trendingDivider.classList.add("tw-mb-2");

      return;
    }

    this._fetchRecentlySearched(recentSearchIds);
    recentlySearchedChip.classList.remove("tw-hidden");
    recentlySearchedChip.click();
  }

  _fetchRecentlySearched(coinIds) {
    const params = new URLSearchParams();
    params.set("coin_ids", coinIds);
    params.set("vs_currency", getActiveCurrency());

    const url = `/${I18n.locale}/portfolios/${this.portfolioId}/recently_searched?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.text())
      .then(result => {
        if (!result) {
          return;
        }

        this.shortResultsContainerTarget.classList.remove("tw-hidden");
        this.shortResultsTarget.innerHTML = result;
        this._updateAllStars();
      });
  }

  _handlePortfolioRefresh() {
    window.addEventListener("gecko-modal-close", (e) => {
      if (e.detail.modalId === "portfolio_add_coin" && this._reload) {
        window.location.reload();
      }
    });
  }

  _updateAllStars() {
    const coinIdStarsMap = {}
    for (const id of this.portfolioCoinIds) {
      coinIdStarsMap[id] = true;
    }

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const starred = !!coinIdStarsMap[coinId];

      star.classList.toggle("far", !starred);
      star.classList.toggle("fas", starred);
      star.classList.toggle("tw-text-yellow-500", starred);
    });
  }

  _removeFromPortfolioCoins(coinId) {
    let coinIdIndex = this.portfolioCoinIds.indexOf(coinId);

    if (coinIdIndex !== -1) {
      this.portfolioCoinIds.splice(coinIdIndex, 1);
    }
  }

  _focusSearchInput() {
    window.addEventListener(EventFocusPortfolioCoinSearch, (e) => {
      e.preventDefault();
      setTimeout( () => { this.searchInputTarget.focus() }, 10 );
    });
  }
}
