import { Controller } from "stimulus";
import { getCSRFToken, setUpdatedCsrfMetaContent } from "../../util";
import { EventMoreContentLoaded } from "../../events";
import { trackEvent } from "../../analytics";
import { VIEW_PORTFOLIO_MODAL } from "../../mixpanel_config";

const CHIP_MAX_LIMIT = 5;

export default class extends Controller {
  // For steps and progressIndicator toggling
  _pageIndex = 0;
  _totalMaxSteps = 2;
  _activeStepIndex = 0;

  // For handling adding coins into portfolio
  _portfolioCoinIds = [];
  _addedCoinsCount = 0;
  _reload = false;

  idCounters = {
    chain: 0,
    category: 0,
  }

  selectedIds = {
    chain: [],
    category: [],
  };

  static targets = [
    "progressIndicator", "pageContainer",

    // Step 1
    "chainContainer", "categoriesContainer", "chainCounter", "categoriesCounter", "nextButton",

    // Step 2
    "loading", "recommendationsContainer", "coinsCounter", "recommendedCoinsActions", "emptyCoinsActions"
  ];

  connect() {
    this._portfolioId = +this.element.dataset.portfolioId;

    this._getCurrentPortfolioCoinIds();
    this._toggleActiveStep();
    this._handlePortfolioRefresh();
    this._handleMoreChipLoaded();
  }

  nextPage() {
    this._pageIndex += 1;
    this._activeStepIndex += 1;

    if (this._pageIndex < this._totalMaxSteps) {
      this._togglePageVisibility();
      this._toggleActiveStep();
      this._getRecommendations();
      this._trackViewRecommendationsResults();
    } else {
      Modal.hide("portfolio_get_recommendations");
    }
  }

  previousPage() {
    this._pageIndex -= 1;
    this._activeStepIndex -= 1;

    this._togglePageVisibility();
    this._toggleActiveStep();
  }

  handleSelect(e) {
    const selectedButton = e.currentTarget;
    const type = selectedButton.dataset.type;
    const id = type === "chain" ? +selectedButton.dataset.chainId : +selectedButton.dataset.categoryId;

    if (this._hasCurrentId(type, id)) {
      this._removeCurrentId(type, id);
    } else {
      this._addCurrentId(type, id);
    }

    this._toggleNextButton();
    this._toggleChipsFromLimit(type);
  }

  handleCoin(e) {
    const coinId = +e.currentTarget.dataset.coinId;
    this._reload = true;

    if (this._portfolioCoinIds.includes(coinId)) {
      this._removeCoinFromPortfolio(coinId);
      return;
    }

    this._addCoinToPortfolio(coinId);
  }

  async _addCoinToPortfolio(coinId) {
    this.loadingTarget.classList.remove("!tw-hidden");

    await setUpdatedCsrfMetaContent();
    const url = `/en/portfolios/${this._portfolioId}/coins/${coinId}`;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => {
      if (!response.ok) {
        Toaster.toast("An unknown error occurred", {
          icon: {
            classes: "fas fa-times tw-text-danger-500"
          }
        });
      } else {
        this._addedCoinsCount++;
        this.coinsCounterTarget.innerHTML = this._addedCoinsCount;

        this._portfolioCoinIds.push(coinId);
        this._updateAllStars();

        this.loadingTarget.classList.add("!tw-hidden");
      }
    })
  }

  _removeCoinFromPortfolio(coinId) {
    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this._portfolioId}/coins/${coinId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          this._removeFromPortfolioCoins(coinId);
          this._updateAllStars();

          this._addedCoinsCount--;
          this.coinsCounterTarget.innerHTML = this._addedCoinsCount;
        }
      }).catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
    });
  }

  _getCurrentPortfolioCoinIds() {
    fetch(`/${I18n.locale}/portfolios/all_coins`)
      .then(async response => {
        if (!response.ok) {
          throw new Error(`Could not retrieve portfolio data. Error ${response.status}.`);
        }

        const data = await response.json();
        if (!data) {
          throw new Error(`Could not retrieve portfolio data. Invalid portfolio data returned.`);
        }

        const portfolio_data = data.find(p => p.id === this._portfolioId);
        if (portfolio_data) {
          this._portfolioCoinIds = portfolio_data["coin_ids"]
        }
      });
  }

  _updateAllStars() {
    const coinIdStarsMap = {}
    for (const id of this._portfolioCoinIds) {
      coinIdStarsMap[id] = true;
    }

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const starred = !!coinIdStarsMap[coinId];
      star.classList.toggle("far", !starred);
      star.classList.toggle("fas", starred);
      star.classList.toggle("tw-text-yellow-500", starred);
    });
  }

  _removeFromPortfolioCoins(coinId) {
    let coinIdIndex = this._portfolioCoinIds.indexOf(coinId);
    if (coinIdIndex !== -1) {
      this._portfolioCoinIds.splice(coinIdIndex, 1);
    }
  }

  _handlePortfolioRefresh() {
    window.addEventListener("gecko-modal-close", (e) => {
      if (e.detail.modalId === "portfolio_get_recommendations" && this._reload) {
        window.location.reload();
      }
    });
  }

  _handleMoreChipLoaded() {
    window.addEventListener(EventMoreContentLoaded, (e) => {
      this._toggleChipsFromLimit("chain");
      this._toggleChipsFromLimit("category");

      this._removeDuplicateChips("chain");
      this._removeDuplicateChips("category");
    });
  }

  _getRecommendations() {
    this.loadingTarget.classList.toggle("!tw-hidden");

    const params = new URLSearchParams();

    if( this.selectedIds["chain"].toString() !== "") {
      params.set("chains", this.selectedIds["chain"].toString());
    }

    if( this.selectedIds["category"].toString() !== "") {
      params.set("categories", this.selectedIds["category"].toString());
    }

    const url = `/${I18n.locale}/portfolios/${this._portfolioId}/recommendations?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.json())
      .then(result => {
        this.recommendationsContainerTarget.innerHTML = result.html;
        this.recommendationsContainerTarget.classList.remove("tw-hidden");
        this._updateAllStars();
        this.loadingTarget.classList.toggle("!tw-hidden");

        if (result.no_coins) {
          this.emptyCoinsActionsTarget.classList.remove("!tw-hidden");
          this.recommendedCoinsActionsTarget.classList.add("!tw-hidden");
        } else {
          this.recommendedCoinsActionsTarget.classList.remove("!tw-hidden");
          this.emptyCoinsActionsTarget.classList.add("!tw-hidden");
        }
      });
  }

  _removeDuplicateChips(type) {
    const container = type === "chain" ? this.chainContainerTarget : this.categoriesContainerTarget;
    const chips = container.querySelectorAll(`button[data-type="${type}"]`);
    const ids = new Set();

    chips.forEach(chip => {
      const chipId = chip.getAttribute(`data-${type}-id`);
      if (ids.has(chipId)) {
        chip.remove();
      } else {
        ids.add(chipId);
      }
    });
  }

  _hasCurrentId(type, id) {
    return this.selectedIds[type].includes(id);
  }

  _addCurrentId(type, id) {
    this.idCounters[type]++;
    this.selectedIds[type].push(id);

    const target = type === "chain" ? this.chainCounterTarget : this.categoriesCounterTarget;
    target.innerHTML = this.idCounters[type];
  }

  _removeCurrentId(type, id) {
    this.idCounters[type]--;

    let removeIdIndex = this.selectedIds[type].indexOf(id);
    this.selectedIds[type].splice(removeIdIndex, 1);

    const target = type === "chain" ? this.chainCounterTarget : this.categoriesCounterTarget;
    target.innerHTML = this.idCounters[type];
  }

  _toggleNextButton() {
    if (this._totalChipsSelected() > 0) {
      this._enableButton();
    } else {
      this._disableButton();
    }
  }

  _totalChipsSelected() {
    return Object.values(this.idCounters).reduce((total, count) => total + count, 0);
  }

  _enableButton() {
    this.nextButtonTarget.disabled = false;
  }

  _disableButton() {
    this.nextButtonTarget.disabled = true;
  }

  _toggleChipsFromLimit(type) {
    const container = type === "chain" ? this.chainContainerTarget : this.categoriesContainerTarget;

    if (this.idCounters[type] === CHIP_MAX_LIMIT) {
      this._toggleUnselectedChips(type, container, true);
    } else {
      this._toggleUnselectedChips(type, container, false);
    }
  }

  _toggleUnselectedChips(type, container, disable) {
    const chips = container.querySelectorAll(`button[data-type="${type}"]`);

    chips.forEach(chip => {
      const chipId = +chip.getAttribute(`data-${type}-id`);
      if (!chipId) return;

      if (!this.selectedIds[type].includes(chipId)) {
        chip.disabled = disable;
      }
    });
  }

  _togglePageVisibility() {
    this.pageContainerTargets.forEach((container, i) => {
      container.classList.toggle("tw-hidden", i !== this._pageIndex);
    });
  }

  _toggleActiveStep() {
    this.progressIndicatorTargets.forEach((step, i) => {
      step.classList.toggle("active", i === this._activeStepIndex);
    });
  }

  _trackViewRecommendationsResults() {
    trackEvent(VIEW_PORTFOLIO_MODAL, { modal_name: "portfolio_recommendation_result" })
  }
}
