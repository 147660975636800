import { Controller } from "stimulus";
import { formatCurrency, getActiveCurrency } from "../../util/currency";
import { staticPercentChangeTemplate } from "../../template/price_templates";
import { EventCurrencyChanged, EventPortfolioTransactionAdded } from "../../events";
import { throttle } from "../../util";

const EXPONENT_CONFIG = { significantFigures: 4, maximumDecimalTrailingZeroes: 4 }
export default class extends Controller {
  static targets = ["skeleton", "topGainer", "price", "signedPrice", "percent"];

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
    this.overrideUrl = this.element.dataset.overrideUrl;

    this.refreshPortfolioValues = throttle(this._refreshPortfolioValues.bind(this), 1000, true);
    this.refreshPortfolioValues();

    window.addEventListener(EventCurrencyChanged, () => this.refreshPortfolioValues());
    window.addEventListener(EventPortfolioTransactionAdded, () => this.refreshPortfolioValues());
  }


  _refreshPortfolioValues() {
    this.toggleSkeleton(this.skeletonTargets, false);

    const currency = getActiveCurrency();
    const baseUrl = this.overrideUrl || `/en/portfolios/${this.portfolioId}/overview`;

    const url = `${baseUrl}?vs_currency=${currency}`
    fetch(url, { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        this.handlePortfolioValues(json);
        this.handlePortfolioCoinValues(json);

        this.toggleSkeleton(this.skeletonTargets, true);
      });
  }

  handlePortfolioValues(data) {
    this.topGainerTargets.forEach((target) => {
      if (target.dataset.attr === "top_gainer_image_url") {
        return target.src = data.top_gainer_image_url;
      }

      if (target.dataset.attr === "top_gainer_page_url") {
        return target.href = ""; // TODO: Fix temporary link-back to same page due to Mobile API issue
      }

      target.innerText = data[target.dataset.attr];
    });


    this.priceTargets.forEach((target) => {
      const attr = target.dataset.attr;
      const value = data[attr];

      const absoluteValue = Math.abs(value);
      const showExponent = absoluteValue > 0 && absoluteValue <= 1;

      target.innerHTML = formatCurrency(value, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
    });


    this.signedPriceTargets.forEach((target) => {
      const attr = target.dataset.attr;
      const value = data[attr];
      const suffix = value >= 0 ? "+" : "-";

      const absoluteValue = Math.abs(value);
      const showExponent = absoluteValue > 0 && absoluteValue <= 1;

      target.innerHTML = suffix + formatCurrency(absoluteValue, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
      target.classList.toggle("gecko-up", value >= 0);
      target.classList.toggle("gecko-down", value < 0);
    });


    this.percentTargets.forEach((target) => {
      let attr = target.dataset.attr;
      let value = data[attr];

      target.innerHTML = staticPercentChangeTemplate(value);
    });
  }

  handlePortfolioCoinValues(data) {
    data?.portfolio_coins?.forEach(portfolio_coin => {
      let portfolioCoinId = portfolio_coin.portfolio_coin_id
      let portfolioCoinRow = this.element.querySelector(`tr[data-portfolio-coin-id="${portfolioCoinId}"]`);

      if (!portfolioCoinRow) {
        return;
      }

      portfolioCoinRow.querySelectorAll(".gecko-content[data-attr]").forEach(target => {
        const type = target.dataset.type;
        const value = portfolio_coin[target.dataset.attr];

        const absoluteValue = Math.abs(value);
        const showExponent = absoluteValue > 0 && absoluteValue <= 1;

        if (target.dataset.useForSort === "true") {
          target.closest("td").dataset.sort = value;
        }

        switch (type) {
          case "percent":
            target.innerHTML = staticPercentChangeTemplate(value);
            return;
          case "number":
            target.innerText = value;
            return;
          default:
            target.innerHTML = formatCurrency(value, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
            return;
        }
      })
    });

    // Set JSON on the data attribute to share with other controllers.
    this.element.dataset.portfolioCoinsData = JSON.stringify(data.portfolio_coins);
  }


  toggleSkeleton(target, finished) {
    let skeletonTargets = [].concat(target);

    skeletonTargets.forEach(skeletonTarget => {
      let loadingTarget = skeletonTarget.querySelector(".gecko-loading");
      let contentTarget = skeletonTarget.querySelector(".gecko-content");

      loadingTarget.classList.toggle("tw-hidden", finished);
      contentTarget.classList.toggle("tw-hidden", !finished);
    });
  }
}
